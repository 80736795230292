<template>
    <div>
        <PageHeader title="Dashboard">
            <SimpleDropDown class="year" v-tooltip="'Display Year'" :options="yearArray" v-model="selectedYear"/>
        </PageHeader>
        <div class="wrapper">

            <div class="statsHolder">

                <div class="charts">

                    <div class="chartHolderGroup">
                        <h2>Monthly gross</h2>
                        <div class="chartHolder" v-if="isLoading(['getDashboardStats'])">
                            <div class="loadingMessage"><PageLoader/></div>
                        </div>
                        <div class="chartHolder" v-else-if="stats && stats.annualData && stats.annualData.data.length > 0">
                            <LineChart :labels="stats.annualData.months" :data="stats.annualData.data"/>
                        </div>
                        <div class="chartHolder" v-else>
                            <div class="loadingMessage">No data to show</div>
                        </div>
                    </div>

                    <div class="chartHolderGroup">
                        <h2>Weekly gross</h2>
                        <div class="chartHolder" v-if="isLoading(['getDashboardStats'])">
                            <div class="loadingMessage"><PageLoader/></div>
                        </div>
                        <div class="chartHolder" v-else-if="stats && stats.annualDataByWeek && stats.annualDataByWeek.data.length > 0">
                            <LineChart :labels="stats.annualDataByWeek.labels" :data="stats.annualDataByWeek.data"/>
                        </div>
                        <div class="chartHolder" v-else>
                            <div class="loadingMessage">No data to show</div>
                        </div>
                    </div>

                    <div class="chartHolderGroup">
                        <h2>Gross by Payroll Periods</h2>
                        <div class="chartHolder" v-if="isLoading(['getDashboardStats'])">
                            <div class="loadingMessage"><PageLoader/></div>
                        </div>
                        <div class="chartHolder" v-else-if="stats && stats.annualPayRollData && stats.annualPayRollData.data.length > 0">
                            <LineChart :labels="stats.annualPayRollData.labels" :data="stats.annualPayRollData.data" :hideXScale="true"/>
                        </div>
                        <div class="chartHolder" v-else>
                            <div class="loadingMessage">No data to show</div>
                        </div>
                    </div>

                    <div class="chartHolderGroup">
                        <h2>Gross by dispatcher</h2>
                        
                        <div class="usersStats">
                            <div class="item header">
                                <div></div>
                                <div>Name</div>
                                <div>Gross</div>
                                <div>%</div>
                                <div>No. of loads</div>
                            </div>
                            <div v-if="isLoading(['getDashboardStats'])">
                                <div class="loadingMessage"><PageLoader/></div>
                            </div>
                            <div v-else-if="stats && stats.users && stats.users.length > 0">
                                <div class="item" :key="user._id" v-for="user of stats.users">
                                    <div class="imageHolder">
                                        <div class="image" :style="`background-image: url('${user.full.profileImage}')`"></div>
                                    </div>
                                    <div class="name">{{ user.name }}</div>
                                    <div class="gross">$ {{ priceConverter(user.gross.toFixed(0)) }}</div>
                                    <div class="grossPercent">{{ Number((user.gross * 100)/stats.loads.gross).toFixed(2) }}%</div>
                                    <div class="count">{{ user.count }} <span>{{ user.count == 1 ? 'load' : 'loads' }}</span></div>
                                </div>
                            </div>
                            <div v-else>
                                <div class="loadingMessage">No data to show</div>
                            </div>
                        </div>

                    </div>
                    
                </div>

                <div class="numericalStats">


                    <div class="chartHolderGroup">
                        <h2>Annual gross by users</h2>
                        <div class="chartHolder" v-if="isLoading(['getDashboardStats'])">
                            <div class="loadingMessage"><PageLoader/></div>
                        </div>
                        <div class="chartHolder flex" v-else-if="usersPercentages.data.length > 0">
                            <DoughnutChart :labels="usersPercentages.labels" :datasets="usersPercentages.dataset"/>
                        </div>
                        <div class="chartHolder" v-else>
                            <div class="loadingMessage">No data to show</div>
                        </div>
                    </div>

                    <div class="statsGroup" v-if="!isLoading(['getDashboardStats']) && stats && stats.loads">
                        <div class="_title"><i class="fas fa-dollar-sign"></i><span>Gross income</span></div>
                        <div class="_value">$ {{ stats.loads ? stats.loads.formattedGross : 0 }}</div>
                    </div>

                    <div class="statsGroup" v-if="!isLoading(['getDashboardStats']) && stats && stats.loads">
                        <div class="_title"><i class="fa-solid fa-boxes-stacked"></i><span>Number of loads</span></div>
                        <div class="_value">{{ numOfLoads }} {{ numOfLoads == 1 ? 'load' : 'loads' }}</div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import LineChart from '../components/charts/LineChart.vue'
import BarChart from '../components/charts/BarChart.vue'
import DoughnutChart from '../components/charts/DoughnutChart.vue'
import moment from 'moment'

    export default {
        components: {
            LineChart,
            BarChart,
            DoughnutChart
        },
        computed: {
            ...mapGetters(['user'])
        },
        data() {
            return {
                stats: null,
                selectedYear: moment.utc().format("YYYY")
            }
        },
        computed: {
            yearArray() {
                let currentYear = Number(moment.utc().format("YYYY"));
                let array = [];

                array.push(currentYear + 1);
                array.push(currentYear);
                array.push(currentYear-1);
                array.push(currentYear-2);
                array.push(currentYear-3);

                return array;
            },
            numOfLoads() {
                if(!this.stats.loads) return 0;
                return this.stats.loads.count;
            },
            annualData() {
                if(this.stats && this.stats.annualData) {
                    return this.stats.annualData;
                }
                return null;
            },
            annualDatasets() {
                if(!this.annualData || !this.annualData.data) return [];
                return [
                    {
                        label: 'Annual Gross by month',
                        borderColor: "#007aff",
                        data: this.annualData.data || [],
                        lineTension: 0.3,
                        pointBackgroundColor: 'blue',
                        fill: true,
                        backgroundColor: 'rgba(0, 122, 255, 0.1)'
                    }
                ]
            },
            usersData() {
                if(this.stats && this.stats.users) {
                    return this.stats.users;
                }
                return null;
            },
            annualDataByWeekDataset() {
                if(!this.stats || !this.stats.annualDataByWeek) return [];
                return [
                    {
                        label: 'Annual Gross by week',
                        borderColor: "#007aff",
                        data: this.stats.annualDataByWeek.data || [],
                        lineTension: 0.3,
                        pointBackgroundColor: 'blue',
                        fill: true,
                        backgroundColor: 'rgba(0, 122, 255, 0.1)'
                    }
                ]
            },
            annualPayRollDataset() {
                if(!this.stats || !this.stats.annualPayRollData) return [];
                return [
                    {
                        label: 'Payroll Period Gross',
                        borderColor: "#007aff",
                        data: this.stats.annualPayRollData.data || [],
                        lineTension: 0.3,
                        pointBackgroundColor: 'blue',
                        fill: true,
                        backgroundColor: 'rgba(0, 122, 255, 0.1)'
                    }
                ]
            },
            usersPercentages() {
                let item = {
                    labels: [],
                    data: []
                };

                if(!this.stats) return item;
                if(!this.stats.users) return item;
                if(this.stats.users.length <= 0) return item;

                for(const user of this.stats.users) {
                    item.labels.push(user.name);
                    item.data.push(user.gross);
                }

                item.dataset = [
                    {
                        borderColor: 'transparent',
                        data: item.data || [],
                        backgroundColor: ['#D98880', '#85C1E9', '#76D7C4', '#BB8FCE', '#E59866', '#5DADE2'],
                        spacing: 10
                    }
                ]

                return item;
            }
        },
        methods: {
            getDashboardStats() {
                this.ajax('getDashboardStats', {
                    url: '/dashboardStats',
                    method: 'POST',
                    data: {
                        value: this.selectedYear
                    }
                }, (err, body) => {
                    if(err) {
                        return;
                    }
                    this.stats = body;
                });
            }
        },
        mounted() {
            this.getDashboardStats();
        },
        watch: {
            selectedYear() {
                this.getDashboardStats();
            }
        }
    }
</script>

<style lang="scss" scoped>
.statsHolder {
    display: grid;
    grid-template-columns: minmax(0, 1fr) 400px;
    column-gap: 30px;
    align-items: start;
}

.numericalStats, .charts {
    display: grid;
    row-gap: 30px;
}

.numericalStats {
    position: sticky;
    top: 30px;
    left: 0;
}

.statsGroup {
    box-shadow: $boxShadow;
}

.chartHolder {
    padding: 20px;
    background: $bgSecondary;
    border-radius: $borderRadius;
    box-shadow: $boxShadow;
    &.flex {
        display: flex;
        justify-content: center;
    }
}

.usersStats {
    background: $bgSecondary;
    border-radius: $borderRadius;
    box-shadow: $boxShadow;
    .item {
        display: grid;
        grid-template-columns: 54px minmax(0, 1fr) 200px 100px 125px;
        height: 54px;
        align-items: center;
        column-gap: 10px;
        padding: 0 10px;
        transition: ease 0.3s;
        border-radius: 5px;
        font-size: 13px;
        .image {
            width: 44px;
            height: 44px;
            background-position: center;
            background-size: cover;
            border-radius: 50%;
        }
        &.header {
            font-weight: bold;
            border-bottom: 1px solid $borderColor;
            border-radius: 0;
            text-transform: uppercase;
        }
        .count {
            font-weight: bold;
            span {
                opacity: 0.8;
            }
        }
        &:hover {
            transform: scale(1.01);
            background: $buttonColor2;
            color: $buttonColor;
            &.header {
                background: inherit;
                transform: none;
                color: inherit;
            }
        }
    }
}

.chartHolderGroup {
    display: grid;
    row-gap: 20px;
    h2 {
        text-transform: uppercase;
        font-size: 16px;
    }
}

.loadingMessage {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 100px 0;
}
</style>