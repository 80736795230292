<template>
    <Doughnut :width="300" :chartData="chartData" :chartOptions="chartOptions"/>
</template>

<script>
import { Doughnut } from 'vue-chartjs'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
  Plugin
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)


    export default {
        components: {
            Doughnut
        },
        props: ['datasets', 'labels'],
        data() {
            return {
                chartData: {
                    labels: this.labels,
                    datasets: this.datasets || []
                },
                chartOptions: {
                    responsive: false,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: false
                        }
                    }
                }
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>