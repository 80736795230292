<template>
    <div>
        <Bar :chartData="chartData" :chartOptions="chartOptions"/>
    </div>
</template>

<script>
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)


    export default {
        components: {
            Bar
        },
        props: ['datasets', 'labels'],
        data() {
            return {
                chartData: {
                    labels: this.labels,
                    datasets: this.datasets || []
                },
                chartOptions: {
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        y: {
                            display: false,
                            min: 0,
                        }
                    }
                }
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>