<template>
    <div>
        <Line :chartData="chartData" :chartOptions="chartOptions"/>
    </div>
</template>

<script>
import { Line } from 'vue-chartjs'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Plugin,
  Filler,
  BorderRadius
} from 'chart.js'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  CategoryScale,
  Filler
)

    export default {
        components: {
            Line
        },
        props: ['data', 'labels', 'hideXScale', 'label'],
        data() {
            return {
                chartData: {
                    labels: this.labels,
                    datasets: [
                        {
                            label: this.label,
                            borderColor: "#007aff",
                            data: this.data || [],
                            lineTension: 0.3,
                            pointBackgroundColor: 'blue',
                            fill: true,
                            backgroundColor: 'rgba(0, 122, 255, 0.1)'
                        }
                    ]
                },
                chartOptions: {
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        y: {
                            display: true,
                            min: 0,
                        },
                        x: {
                            display: this.hideXScale ? false : true,
                            min: 0,
                        }
                    },
                    
                    plugins: {
                        filler: {
                            propagate: false
                        },
                        legend: {
                            display: false
                        }
                    }
                }
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>